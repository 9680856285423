
import { defineComponent, reactive, toRefs, onMounted, computed } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";

export default defineComponent({
  name: "ConfigRegisterTime",
  props: ["dialogFormVisible"],
  emits: ["close"],
  components: {},
  setup(props, { emit }) {
    let data = reactive<any>({
      init: false,
      data: {},
      option: {},
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20],
        layout: "total, sizes, prev, pager, next",
      },
      query: {
        limit: 10,
        page: 1,
        where: {},
        sort: "-orderid",
      },
      timetype: 0,
      typeid: 0,
      title: "",
      day: "",
    });

    const dialogVisible = computed({
      get() {
        return props.dialogFormVisible;
      },
      set() {
        emit("close");
      },
    });

    const searchChange = (timetype: number, typeid: number, title: string) => {
      data.query.where = {
        timetype: timetype,
        typeid: typeid,
      };
      data.timetype = timetype;
      data.typeid = typeid;
      data.title = title;
      data.page.currentPage = 1;
      data.query.page = 1;
      data.query.limit = data.page.pageSize;
      console.log(data);

      getList();
    };

    const resetSource = () => {
      if (data.day == "") {
        ElMessage.warning({
          showClose: true,
          duration: 10000,
          message: "请选择生效时间",
        });
        return;
      }
      ElMessageBox.confirm("生成号源时间较长，确定要重新生成吗？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "",
      })
        .then(() => {
          const resetdata = {
            timetype: data.timetype,
            typeid: data.typeid,
            day: data.day,
          };
          console.log(resetdata);

          Api.Config.ResetSource(resetdata).then((res: any) => {
            console.log(res);
            data.data = {};
            if (res.Code == 0) {
              ElMessage.success("后台生成中，请稍候查看相关订单，检查有无遗漏");
            } else {
              ElMessage.warning({
                showClose: true,
                duration: 10000,
                message: res.ResultMsg,
              });
            }
          });
        })
        .catch(() => {
          console.log("xx");
        });
    };

    const changePage = ({ pageSize, currentPage }: any) => {
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getList();
    };

    const getList = () => {
      console.log(data.query);
      Api.Config.GetTimeList(data.query).then((res: any) => {
        console.log(res);
        data.data = {};
        if (res.Code == 0) {
          res.Data.data = res.Data.data.sort((a: any, b: any) => {
            return a.ghtype - b.ghtype;
          });
          data.data = res.Data;
          data.page.total = res.Data.total;
          data.page.currentPage = res.Data.page;
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const getOption = () => {
      Api.Config.GetTimeListOption().then((res: any) => {
        console.log(res);
        data.option = res;
        data.init = true;
      });
    };

    const rowSave = (form: any, done: any, loading: any) => {
      form.timetype = data.timetype;
      form.typeid = data.typeid;
      Api.Config.AddTime(form).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const rowUpdate = (form: any, index: any, done: any, loading: any) => {
      console.log(data.timetype);
      console.log(data.typeid);
      form.timetype = data.timetype;
      form.typeid = data.typeid;
      Api.Config.EditTime(form.id, form).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const rowDel = (row: any, index: any, done: any) => {
      ElMessageBox.confirm("删除后无法恢复，确定要删除吗？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "",
      })
        .then(() => {
          row.Status = 0;
          Api.Config.DeleteTime(row.id).then((res: any) => {
            if (res.Code == 0) {
              ElMessage.success("保存成功");
              done(row);
            } else {
              ElMessage.warning({
                showClose: true,
                duration: 10000,
                message: res.ResultMsg,
              });
            }
          });
        })
        .catch(() => {
          console.log("xx");
        });
    };

    const sortableChange = (oldindex: number, newindex: number) => {
      if (oldindex == newindex) return;
      const order = {
        key: data.data.data[newindex].id,
        steps: oldindex - newindex,
      };
      Api.Config.OrderTime(order).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
          getList();
        }
      });
    };

    // const HospitalRemove = (row: any) => {
    //   Api.Config.EditHospital(row.HospitalID, row).then((res: any) => {
    //     if (res.Code == 0) {
    //       ElMessage.success("保存成功");
    //     } else {
    //       ElMessage.warning({
    //         showClose: true,
    //         duration: 10000,
    //         message: res.ResultMsg,
    //       });
    //       getList();
    //     }
    //   });
    // };

    onMounted(() => {
      getOption();
    });

    return {
      ...toRefs(data),
      dialogVisible,
      changePage,
      rowSave,
      rowUpdate,
      rowDel,
      sortableChange,
      //HospitalRemove,
      searchChange,
      resetSource,
    };
  },
});
