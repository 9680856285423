
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
} from "vue";
import { Api } from "@/assets/Api";
import { ElMessage } from "element-plus";
import store from "@/store";
import RegisterTime from "./RegisterTime.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigDoctor",
  components: { RegisterTime },
  setup() {
    let data = reactive<any>({
      init: false,
      data: {},
      option: {},
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20],
        layout: "total, sizes, prev, pager, next",
      },
      query: {
        limit: 10,
        page: 1,
        where: {},
        sort: "-orderid",
      },
      timeDialogVisible: false,
      timeStatus: [],
    });

    const searchChange = (params: any, done: any) => {
      console.log(params);
      if (!params["hospital"]) {
        ElMessage.warning("请选择医院");
        done();
        return;
      }
      for (let p in params) {
        const field: Column = data.option.column.find(
          (v: Column) => v.prop === p
        );
        if (field.regex) {
          params[p] = { $regex: params[p] };
        }
      }
      data.query.where = params;
      data.page.currentPage = 1;
      data.query.page = 1;
      data.query.limit = data.page.pageSize;
      getList();
      done();
    };

    const changePage = ({ pageSize, currentPage }: any) => {
      if (!data.query.where["hospital"]) {
        return;
      }
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getList();
    };

    const getList = () => {
      console.log(data.query);
      Api.Config.GetDoctorList(data.query).then((res: any) => {
        console.log(res);
        data.data = {};
        if (res.Code == 0) {
          data.data = res.Data;
          data.page.total = res.Data.total;
          data.page.currentPage = res.Data.page;
          data.data.data.forEach((element: any) => {
            element.hospitalid = element.hospitalid + "";
          });
          getTimeStatusList();
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const getTimeStatusList = () => {
      var ids: number[] = [];
      data.data.data.forEach((element: any) => {
        ids.push(element.id);
      });
      if (ids.length == 0) return;
      var action = {
        timetype: 3,
        ids: ids,
      };
      Api.Config.GetTimeStatusList(action).then((res: any) => {
        console.log(res);
        if (res.Code == 0) {
          data.timeStatus = res.Data;
        } else {
          data.data = [];
        }
      });
    };

    const haveTime = computed(() => (id: number) => {
      var status = data.timeStatus.find((item: any) => item.id == id);
      if (status) {
        return data.timeStatus.find((item: any) => item.id == id).have
          ? "primary"
          : "warning";
      } else {
        return "warning";
      }
    });

    const getOption = () => {
      Api.Config.GetDoctorListOption().then((res: any) => {
        console.log(res);
        const localHospital: string = store.getters.localHospital;
        if (localHospital) {
          const jsonHospital = JSON.parse(localHospital);
          // jsonHospital.forEach((element: any) => {
          //   element.value = element.value + "";
          // });
          res.column.filter((c: any) => {
            if (c.prop == "hospital") c.dicData = jsonHospital;
            if (c.prop == "hospitalid") c.dicData = jsonHospital;
          });
        }
        data.option = res;
        data.init = true;
      });
    };

    const rowSave = (form: any, done: any, loading: any) => {
      // form.hospitalid = parseInt(form.hospitalid);
      console.log(form);
      if (form.photo.length == 0) {
        form.photo = "";
      } else {
        form.photo = form.photo[0];
      }
      Api.Config.AddDoctor(form).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          if (Object.keys(data.query.where).length != 0) getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const rowUpdate = (form: any, index: any, done: any, loading: any) => {
      Api.Config.EditDoctor(form.id, form).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const DoctorRemove = (row: any) => {
      Api.Config.EditDoctor(row.id, row).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
          getList();
        }
      });
    };

    const RegisterTimeRef = ref<InstanceType<typeof RegisterTime>>();
    const SetTime = (row: any) => {
      data.timeDialogVisible = true;
      RegisterTimeRef.value?.searchChange(3, row.id, row.doctorname);
    };

    onMounted(() => {
      getOption();
    });

    return {
      ...toRefs(data),
      searchChange,
      changePage,
      rowSave,
      rowUpdate,
      DoctorRemove,
      SetTime,
      RegisterTimeRef,
      haveTime,
    };
  },
});
